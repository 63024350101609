function headerScroll() {
    const scrolled = Math.round($(window).scrollTop())
    const header = $('header.site-header')

    if(scrolled) {
        header.addClass('scroll')
    }else{
        header.removeClass('scroll')
    }

}

headerScroll()

$(window).scroll(headerScroll)
if( document.querySelector('body').classList.contains('page-template-page-faq') ){
    let faq = document.querySelector('.faq');
    let question = faq.querySelectorAll('.question');

    question.forEach(element => {

        element.addEventListener('click', ()=>{

            if(element.parentElement.classList.contains('show')){
                element.parentElement.classList.remove('show');
            }else{
                element.parentElement.classList.add('show');
            }

            question.forEach(el => {
                if( el != element ){
                    el.parentElement.classList.remove('show');
                }
            });

        });
    });
}
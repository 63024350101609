$(document).on('click', '.change-time', function() {
    let elem = $(this)
    let currentTime = new Date().getTime()
    let addTime = 0
    let totalTime = 0
    let interpreter = {
        second: 1000,
        minute: 60000,
        hour: 3600000,
        day: 86400000
    }
    let seconds = elem.data('seconds') || 0
    let minutes = elem.data('minutes') || 0
    let hours = elem.data('hours') || 0
    let days = elem.data('days') || 0
    let inputs = $('.' + elem.data('input'))
    let formats = {
        dmY: (time) => {
            return new Date(time).dmY()
        },
        time: (time) => {
            return new Date(time).time()
        },
        raw: (time) => {
            return time
        }
    }

    seconds *= interpreter.second
    minutes *= interpreter.minute
    hours *= interpreter.hour
    days *= interpreter.day

    addTime = seconds + minutes + hours + days
    totalTime = currentTime + addTime

    inputs.each(function() {
        let input = $(this)
        let format = input.data('format') || 'raw' 
        let time = formats[format](totalTime)

        input.val(time)
    })
})
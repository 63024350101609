let fileSizes = {
    mb: 1048576,
    kb: 1024,
}
let fileAllowedTypes = [
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/webm',

    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/vnd.microsoft.icon',
    'image/webp',

    'application/msword',
    'application/zip',
    'application/pdf',
    'application/javascript',
    'application/json',
    'application/x-zip',
    'application/x-zip-compressed',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

    'text/css',
    'text/csv',
    'text/html',
    'text/javascript',
    'text/php',
    'text/xml',

    'video/mpeg',
    'video/mp4',
    'video/ogg',
    'video/webm',
]
let renameFileFormates = {
    'svg+xml': 'svg',
    'vnd.microsoft.icon': 'ico',
    'x-zip': 'zip',
    'x-zip-compressed': 'zip',
    'javascript': 'js',
    'msword': 'doc',
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
}
let fileMaxSize = fileSizes.mb * 2
let maxFilesCount = 6

let fileTooLargeError = 'Файл слишком много весит'
let fileTypeError = 'Недопустимый тип файла'
let fileMaxCountError = `Вы можете загрузить не более ${maxFilesCount} `

if(maxFilesCount == 1) {
    fileMaxCountError += 'файла'
}else if(maxFilesCount > 1 && maxFilesCount < 5) {
    fileMaxCountError += 'файлов'
}else{
    fileMaxCountError += 'файлов'
}

$(document).on('click', '.remove-file', function(e) {
    let elem = $(this)
    let id = elem.data('id')
    let block = elem.parents('.file-block')
    let uploader = block.find('input')[0]

    uploader.filesList.splice(id, 1)
    renderFileBlock(uploader)
    e.preventDefault()
})

function renderFileBlock(uploader) {
    let elem = $(uploader)
    let block = elem.parents('.file-block')
    let filesList = uploader.filesList || []
    let filesWrapper = block.find('.files-line')
    let oldFilePreviews = filesWrapper.find('.file-preview')
    let emptyMessage = block.find('.empty-message')

    oldFilePreviews.remove()

    if(filesList.length) {
        let filePreviews = []

        $.each(filesList, (index, file) => {
            let previewClasses = ['file-preview']
            let fileName = file.name
            let fileType = file.type.split('/')
            let fileGroup = fileType[0]
            let fileFormate = renameFileFormates[fileType[1]] || fileType[1]
            let fileSize = (file.size / fileSizes.mb).toFixed(2)

            previewClasses.push(fileFormate)

            let filePreview = `
            <div class="${previewClasses.join(' ')}" data-id="${index}">
                <div class="file-avatar">${fileFormate}</div>
                <div class="file-text article-mc">
                    <div class="title crop-text">${fileName}</div>
                    <div class="label">${fileFormate} ${fileSize} МБ.</div>
                </div>
                <div class="icon-cancel remove-file" data-id="${index}"></div>
            </div>
            `

            filePreviews.push(filePreview)
        })

        filesWrapper.append(filePreviews.join(''))
        cropText()
        emptyMessage.hide()
    }else{
        emptyMessage.show()
    }
}

$(document).on('change', '.file-block input', function(e) {
    let uploader = e.target

    if(!uploader.filesList) {
        uploader.filesList = []
    }

    if(!uploader.files.length) {
        return
    }

    let filesLimitError = false

    let files = Array.from(uploader.files)

    files.forEach(file => {
        if(Object.keys(uploader.filesList).length >= maxFilesCount) {
            filesLimitError = true
            return
        }
        let name = file.name
        let size = file.size
        let type = file.type

        let errors = 0

        if(size > fileMaxSize) {
            addNotif({
                title: name,
                text: fileTooLargeError,
                icon: 'close',
                color: 'red',
                timeout: 4000
            })

            errors++
        }

        if(!fileAllowedTypes.includes(type)) {
            addNotif({
                title: name,
                text: fileTypeError,
                icon: 'close',
                color: 'red',
                timeout: 4000
            })

            errors++
        }

        if(!errors) {
            uploader.filesList.push(file)
        }
    })

    if(filesLimitError) {
        addNotif({
            title: 'Ошибка',
            text: fileMaxCountError,
            icon: 'close',
            color: 'red',
            timeout: 4000
        })
    }

    uploader.value = ''
    renderFileBlock(uploader)
})
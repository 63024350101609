const minDeadlineHours = 2
let minDeadlineHoursText = minDeadlineHours + ' час'

if(minDeadlineHours > 1 && minDeadlineHours < 5) {
    minDeadlineHoursText = minDeadlineHours + ' часа'
}else{
    minDeadlineHoursText = minDeadlineHours + ' часов'
}

if($('.add-task').length) {
    $(document).on('click', '#send_add_task', function() {
        let elem = $(this)
        let titleField = $('#task_title')
        let descriptionField = $('#task_description')
        let dateField = $('#task_date')
        let timeField = $('#task_time')
        let priceField = $('#task_price')
        let files = $('#task_files')[0].filesList || []
        let urlField = $('#task_link')
        let checkFields = [titleField, descriptionField, dateField, timeField, priceField]
        let errors = 0
    
        for(field of checkFields) {
            errors += field.check()
        }
    
        if(!errors) {
            let title = titleField.getValue()
            let description = descriptionField.getValue()
            let deadline = new Date((dateField.getValue() + ' ' + timeField.getValue()).date()).getTime()
            let now = new Date().getTime()
            let price = priceField.getValue()
            let url = urlField.getValue()
            let data = {title, description, url, deadline, price}

            if(now > deadline) {
                addNotif({
                    title: 'Ошибка',
                    text: 'Выберите дату позже',
                    icon: 'close',
                    color: 'red',
                    timeout: 4000
                })
            }else if((deadline - now) < (3600000 * (minDeadlineHours * 0.9))) {
                addNotif({
                    title: 'Ошибка',
                    text: 'Минимальное время дедлайна - ' + minDeadlineHoursText,
                    icon: 'close',
                    color: 'red',
                    timeout: 4000
                })
            }else{

                function sendTask() {
                    socket.emit('addTask', data)
                }
        
                if(files.length) {
                    let length = files.length
                    let index = 0
        
                    data.files = []
        
                    function process_file(file) {
                        let file_object = {
                            name: file.name,
                            type: file.type,
                            size: file.size,
                        }
                        let reader = new FileReader()
        
                        reader.onload = res => {
                            let base64 = res.target.result
                            file_object.base64 = base64
        
                            data.files.push(file_object)
        
                            index++
        
                            if(index == length) {
                                sendTask()
                            }
                        }
        
                        reader.readAsDataURL(file)
                    }
        
                    for(file of files) {
                        process_file(file)
                    }
                }else{
                    sendTask()
                }
                
                elem.disable(true)            
            }
    
        }
    })
    
    socket.on('taskAdded', hash => {
        let url = '/tasks/' + hash + '/'
        window.location.href = url
    })
}
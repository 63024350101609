function focusCodeInput(wrapper){
    let inputs = $('.code-input')
    let iteration = true
    let code = ''

    inputs.each(function(){
        let input = $(this)
        let val = input.val().replace(/[^0-9]/gm, '').substr(0, 1)

        if(iteration){
            code += val
            if(!val){
                input.focus()
                iteration = false
            }
        }
    })

    if(iteration){
        inputs.blur()
        wrapper.trigger('change')
        wrapper[0].value = code
    }
}

$(document).on('input', '.code-input', function(e){
    let elem = $(this)
    let val = elem.val().replace(/[^0-9]/gm, '').substr(0, 1)
    let wrapper = elem.parent()

    elem.val(val)

    if(val){
        focusCodeInput(wrapper)
    }
})

$(document).on('keydown', '.code-input', function(e){
    let input = $(this)
    let index = input.index()
    let prev = input.prev()
    let backspaceKey = 8
    let deleteKey = 46
    let beforeVal = e.target.value

    if(e.keyCode == backspaceKey || e.keyCode == deleteKey){
        if(index && !beforeVal){
            prev.focus()
        }
    }
})


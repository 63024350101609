$(document).on('click', '.inline-task-info', function(e) {
    let allow = true
    let banned_tags = ['A', 'BUTTON']
    
    if(banned_tags.indexOf(e.target.tagName) != -1) {
        allow = false
    }

    if($(e.target).hasClass('btn') || $(e.target).parents('.btn').length || $(e.target).parents('a').length) {
        allow = false
    }

    if(allow) {
        const elem = $(this)
        const dropdown = elem.next('.task-dropdown')
    
        dropdown.stop().slideToggle(200)
    }
})
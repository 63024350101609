window.registerData = {}

function register(e){
    let form = $('.auth-form')
    let firstNameField = $('#register_firstname')
    let lastNameField = $('#register_lastname')
    let phoneField = $('#register_phone')
    let emailField = $('#register_email')
    let passwordField = $('#register_password')
    let passwordConfirmField = $('#register_password_confirm')
    let checkbox = $('#register_accert')
    let btn = $('#register_send')
    let errors = 0
    let checkFields = [firstNameField, lastNameField, phoneField, emailField, passwordField, passwordConfirmField, checkbox]
    let codeForm = $('.code-form')

    e.preventDefault()

    for(field of checkFields){
        errors += field.check()
    }

    if(!errors){
        let firstName = firstNameField.getValue()
        let lastName = lastNameField.getValue()
        let phone = phoneField.getValue()
        let email = emailField.getValue()
        let password = passwordField.getValue()
        let passwordConfirm = passwordConfirmField.getValue()
        let data = {firstName, lastName, phone, email, password, passwordConfirm}

        if(password != passwordConfirm){
            addNotif({
                title: 'Ошибка',
                text: 'Пароли должны совпадать',
                icon: 'close',
                color: 'red',
                timeout: 4000
            })

            passwordConfirmField.addClass('error')
        }else{
            requestAjax('checkRegisterData', data, (res) => {
                res = JSON.parse(res)
                
                if(res.res == 'error'){
                    displayAuthErrors(res.fields)
                    btn.enable()
                }else{
                    window.registerData = data

                    Object.defineProperty(window, 'registerData', {
                        enumerable: false,
                        configurable: false,
                        writable: false
                    })

                    codeForm.fadeIn(300)
                    updateCodeForm(res.lastSend)
                }

            })

            btn.disable(true)
        }
    }

}

function updateCodeForm(diff = 0){
    let form = $('.code-form')
    let sendAganWrapper = $('.code-form-send-again')
    let waitTime = 120
    let codeInputs = $('.code-inputs')
    let sendAgainMessage = '<span class="send-again link">Отправить снова</span>'
    let sendAgainTimerMessage = 'Вы сможете отправить код снова через <span class="code-timer"></span> с.'

    sendAganWrapper.html('')

    if(diff >= waitTime){
        sendAganWrapper.html(sendAgainMessage)
    }else{
        sendAganWrapper.html(sendAgainTimerMessage)
        
        let time = waitTime - diff
        let timer = $('.code-timer')
        let interval = null

        timer.text(time)

        interval = setInterval(() => {
            time--

            if(time){
                timer.text(time)
            }else{
                clearInterval(interval)
                updateCodeForm(122)
            }
        }, 1000)

    }

}

$(document).on('click', '#register_send', register)

$(document).on('click', '.send-again:not(.disabled)', function(){
    let elem = $(this)

    elem.addClass('disabled')
    requestAjax('sendRegisterCodeQuery', window.registerData.phone, diff => {
        updateCodeForm(diff)
    })
})
function cropText() {
    const elements = $('.crop-text:not(.inited)')

    elements.each(function() {
        const elem = $(this)
        const text = elem.text()

        elem[0].source_text = text

        elem[0].cropText = function() {
            let word = elem[0].source_text
            let scrollWidth = elem[0].clientWidth - elem[0].scrollWidth

            while(scrollWidth && word.length) {
                word = word.slice(0, -1)
                elem.text(word + '...')
                scrollWidth = elem[0].clientWidth - elem[0].scrollWidth
            }
        }
        elem[0].cropText()
    })
}

cropText()
const sliders = {
    'recommendation-items-slider': {
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="btn prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn next"><span class="icon icon-right"></span></button>',
    },
    'team-slider': {
        slidesToShow: 4,
        slidesToScroll: 4,
        // variableWidth: true,
        prevArrow: '<div class="prev icon-arrow-left"></div>',
        nextArrow: '<div class="next icon-arrow-right"></div>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    },
    'reviews-slider': {
        centerMode: true,
        centerPadding: '180px',
        slidesToShow: 3,
        variableWidth: true,
        arrows: false,
    },
    'recent-posts-slider': {
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: '.our-blog .slider-arrow .prev',
        nextArrow: '.our-blog .slider-arrow .next',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    },
    
}

$.each(sliders, (name, options) => {
    const slider = $('.' + name)

    if(slider.length) {
        slider.slick(options)
    }
})
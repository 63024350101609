// ! MENU SCROLL
var menu = $(".js_fixed_header");

$(window).on("scroll", function () {
    var position = $(this).scrollTop();

    if ($(this).scrollTop() > 300) {
        if (!menu.hasClass("fixed")) {
            menu.addClass("fixed").slideDown(300);
        }
    } else {
        if (menu.hasClass("fixed")) {
            menu.slideUp(300, function () {
                menu.removeClass("fixed").slideDown(300);
            });
        }
    }
});
// ! MENU SCROLL END
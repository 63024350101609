function dropdown() {

    let province = document.querySelector('.province');

    if( province ){

        let provinceName = province.querySelector('.name');
        let dropdown = province.querySelector('.dropdown');
        let dropdownList = dropdown.querySelectorAll('li');

        // Клик вне dropdown
        document.addEventListener('click', (el) => {
            if( el.target != province || el.target != dropdown){
                if( dropdown.classList.contains('show') ){
                    dropdown.classList.remove('show');
                }
            }
        });

        // Клик по "Province"
        province.addEventListener('click', (elem)=>{
            elem.stopPropagation();
            // Показываем dropdown
            
            if( !dropdown.classList.contains('show') ){
                dropdown.classList.add('show');
            }else{
                dropdown.classList.remove('show');
            }

        });

        // Выбор города
        dropdownList.forEach(item => {
            item.addEventListener('click', (el)=>{

                // Выбираем 
                provinceName.innerHTML = item.textContent;

                if ( province.classList.contains('error') ) {
                    province.classList.remove('error');
                }
            });
        });
    }

}
dropdown();


function sendLead(form) {

    form    = document.querySelector('.' + form);

    if(form){

        let inputs  = form.querySelectorAll('input');
        let message = form.querySelector('textarea');
        let submit  = form.querySelector('button.btn');

        // Проверка ввода input
        inputs.forEach( input => {
            if ( input.classList.contains('error') ){
                input.addEventListener('input', (el)=>{
                    el.classList.remove('error');
                });
            }
        });

        // Клик по кнопки
        submit.addEventListener('click', (event)=>{
            event.preventDefault();
            // submit.disabled = true;

            let errors = 0;
            let data = {};

            // Проверка "inputs" при клике
            inputs.forEach( el => {
                if( !el.value.length || el.value.trim() == "" ){
                    errors++;
                    el.classList.add('error');
                }else{
                    data[el.name] = el.value;
                }
            });

            
            if( form.querySelector('.province') ){
                let province = form.querySelector('.province');
                let provinceName = province.querySelector('.name');

                // Проверка "Province" при клике
                if( provinceName.textContent == "Province" ){
                    errors++;
                    if( !province.classList.contains('error') ){
                        province.classList.add('error');
                    }
                }else{
                    province.classList.remove('error');
                    data['province'] = provinceName.textContent;
                }
            }

            // Проверка сообщения
            if( message.value.length && message.value.trim() != "" ){
                data['message'] = message.value.trim();
            }

            // Если нет ошибок
            if( !errors ){

                console.log(data);

                requestAjax('add_order', data, res => {
                    res = JSON.parse(res);

                    if( res.result == 'success' ){

                        document.location.href="https://bimma.ca/thank-you/";

                        /*form.insertAdjacentHTML('afterbegin', '<div class="success"><div class="success-title">Your message has been successfully sent.</div><div class="success-description">We will contact you as soon as possible.</div></div>');

                        inputs.forEach( el => {
                            el.value = "";
                        });

                        message.value = "";

                        if( form.querySelector('.province') ){
                            let province = form.querySelector('.province');
                            let provinceName = province.querySelector('.name');
                            provinceName.textContent = "Province";
                        }

                        setTimeout(() => {
                            form.querySelector('.success').remove();
                            submit.disabled = false;
                        }, 4000);*/
                    }
                });
            }



        });

    }
}

sendLead('form-block');
sendLead('contact-form');
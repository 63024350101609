if($('.tasks-archive').length) {
    let feed = $('.tasks-feed')
    let emptyBlock = feed.find('.empty-block')
    let moreBtn = '<button class="btn full grey"><span class="text icon-refresh">Показать больше</span></button>'

    window.task = {
        tasks: [],
        tasksById: {}
    }

    socket.emit('tasksArchive')

    socket.on('tasksArchive', tasks => {
        window.task.tasks = tasks

        $.each(tasks, (key, value) => {
            window.task.tasksById[value.id] = value
        })

        renderTasks()

        emptyBlock.remove()
        feed.append(moreBtn)
    })

    function renderTask(task) {
        let taskId = task.id
        let taskTitle = task.title
        let taskDescription = task.description
        let taskShortDescription = taskDescription
        let taskDeadline = new Date(task.deadline).left()
        let taskPrice = task.price
        let taskUrl = task.url
        let taskUser = task.user_id
        let taskUsername = task.username
        let taskType = task.type || 'static'

        let taskClasses = ['inline-task-item']
        let taskAttrsList = {
            'data-id': taskId
        }

        console.log(task.deadline);
        console.log(taskDeadline);
        console.log('===');

        if(taskUrl) {
            taskUrl = `
                <div class="hidden-link" data-link="${taskUrl}">
                    <span class="text">Посмотреть ссылку</span><span class="icon icon-open"></span>
                </div>
            `
        } else {
            taskUrl = ''
        }

        if(taskType == 'new'){
            taskClasses.push('hidden')
            taskClasses.push('new')
        }

        if(taskShortDescription.length > 255) {
            taskShortDescription = taskShortDescription.substr(0, 255) + '...'
        }

        let taskAttrs = []

        $.each(taskAttrsList, (key, value) => {
            taskAttrs.push(key + '="' + value + '"')
        })

        let structure = `
        <div class="${taskClasses.join(' ')}" ${taskAttrs.join(' ')}>
            <div class="inline-task-content">
                <div class="article inline-task-info">
                    <div class="task-header">
                        <div class="avatar large user-avatar" data-id="${taskUser}">${taskUsername.charAt(0)}</div>
                        <div class="task-header-text">
                            <div class="title"><h5>${taskTitle}</h5></div>
                            <div class="task-meta meta-items">
                                <div class="line">
                                    <div class="meta theme"><span class="icon icon-checkbox"></span><span class="text">${taskUsername}</span></div>
                                    <div class="sep"></div>
                                    <div class="meta">
                                        <span class="icon icon-star-outline theme orange-theme"></span>
                                        <span class="icon icon-star-outline theme orange-theme"></span>
                                        <span class="icon icon-star-outline theme orange-theme"></span>
                                        <span class="icon icon-star-outline theme orange-theme"></span>
                                        <span class="icon icon-star-outline"></span>
                                        <span class="text">(12)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline medium">
                            <div class="line">
                                <div class="task-conditions">
                                    <div class="price">${taskPrice.toLocaleString()} тг.</div>
                                    <div class="meta"><span class="icon icon-time"></span><span class="text">2 часа</span></div>
                                </div>
                                <button class="btn grey"><span class="icon icon-fav"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="task-text">
                        <p>${taskShortDescription}</p>
                    </div>
                </div>

                <div class="task-dropdown article-md">
                    <div class="article-sm">
                        <h5>Описание задания</h5>
                        <p>${taskDescription}</p>
                    </div>
                    <div class="files-line">
                        <div class="line">
                            <a href="#" target="_blank" class="file-preview">
                                <div class="file-avatar">pdf</div>
                                <div class="file-text article-mc">
                                    <div class="title crop-text">Наименование файла</div>
                                    <div class="label">.PDF 0.12 МБ.</div>
                                </div>
                            </a>
                            <a href="#" target="_blank" class="file-preview">
                                <div class="file-avatar">pdf</div>
                                <div class="file-text article-mc">
                                    <div class="title crop-text">Наименование файла</div>
                                    <div class="label">.PDF 0.12 МБ.</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    ${taskUrl}
                    <form class="task-form article-sm">
                        <h5>С учетом комиссии вы получите: ${Math.round(taskPrice * 0.9).toLocaleString()} тг.</h5>
                        <p class="bm">Согласившись на задание, вы подтверждаете, что выполнение задания не связано с обменом денежных средств и не нарушает действующего законодательства.</p>
                        <div class="tags">
                            <div class="line">
                                <h6 class="fz">Шаблоны:</h6>
                                <div class="tag hover">Дорогие задания</div>
                                <div class="tag hover">Дешевые задания</div>
                                <div class="tag grey hover symmetric"><span class="icon icon-add"></span></div>
                                <div class="tag grey hover symmetric"><span class="icon icon-edit"></span></div>
                            </div>
                        </div>
                        <div class="input-wrapper">
                            <textarea id="task_response_message" cols="30" rows="4" class="bg" placeholder="Поздоровайтесь с заказчиком и узнайте подробности задания"></textarea>
                        </div>
                        <div class="inline center full">
                            <div class="line">
                                <button class="btn grey decline-task"><span class="text">Отказаться</span></button>
                                <button class="btn accert-task"><span class="text">Согласиться</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        `

        return structure
    }

    function renderTasks() {
        let tasks = window.task.tasks
        let items = []

        $.each(tasks, (key, value) => {
            items.push(renderTask(value))
        })

        feed.prepend(items.join(''))
    }

    socket.on('tasksArchiveNew', task => {
        task.type = 'new'
        
        window.task.tasks.unshift(task)
        window.task.tasksById[task.id] = task

        let taskStructure = renderTask(task)
        feed.prepend(taskStructure)

        let taskElem = $('.inline-task-item.new')

        taskElem.slideDown(300)

    })
}